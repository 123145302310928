import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(
    private router: Router,
    private bookingService: BookingService) { }

  ngOnInit(): void {
    if (!this.bookingService.customer) {
      this.router.navigate(['home']);
    }
  }

}
