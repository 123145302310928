import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from '../interfaces/customer';
import { Day } from '../interfaces/day';
import { Item } from '../interfaces/item';
import { Slot } from '../interfaces/slot';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss'],
})
export class BookingModalComponent implements OnInit {
  item!: Item;
  day!: Day;
  slot!: Slot;
  name?: Customer['name'];
  surname?: Customer['surname'];
  phone?: Customer['phone'];
  customer?: Customer;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  confirm(): void {
    console.log('conferma');
    if (!this.name || !this.surname || !this.phone) {
      return;
    }
    this.customer = {
      name: this.name,
      surname: this.surname,
      phone: this.phone,
    };
    this.activeModal.close('closeClick');
  }
}
