<h2>Riepilogo</h2>
<ul class="list-group">
  <li class="list-group-item">Servizio: {{ item.data.name }}</li>
  <li class="list-group-item">Data: {{ day.jsdate.toLocaleDateString() }}</li>
  <li class="list-group-item">
    Dalle ore {{ slot.startHour | number: "2.0" }}:{{
      slot.startMinute | number: "2.0"
    }}
    alle ore {{ slot.endHour | number: "2.0" }}:{{
      slot.endMinute | number: "2.0"
    }}
  </li>
  <li *ngIf="customer" class="list-group-item">
    {{ customer.surname }} {{ customer.name }}, tel: {{ customer.phone }}
  </li>
</ul>
