<h1>Seleziona Giorno</h1>
<div class="row">
  <div
    *ngFor="let day of days"
    class="col-6 col-sm-4 text-center"
    (click)="selectDay(this.day)"
  >
    <button
      class="btn btn-outline-primary datebtn"
      [ngClass]="{ active: this.selectedDay?.date === day.date }"
      [disabled]="day.jsdate.getDay() === 0"
    >
      <div class="date-day">{{ day.day }}</div>
      <div class="date-date">{{ day.date }}</div>
      <span class="date-monthyear">{{ day.month }}, {{ day.year }}</span>
    </button>
  </div>
</div>
