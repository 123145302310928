<nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
  <a class="navbar-brand" href="#">AgenziaViola</a>
  <button
    *ngIf="isAdmin"
    class="navbar-toggler hidden-sm-up"
    type="button"
    (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarsDefault"
    aria-controls="navbarsDefault"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    *ngIf="isAdmin"
    [ngbCollapse]="isNavbarCollapsed"
    (click)="isNavbarCollapsed = true"
    class="navbar-nav collapse navbar-collapse"
    id="navbarsDefault"
  >
    <a class="nav-item nav-link" routerLink="/">Home</a>
    <a class="nav-item nav-link" routerLink="/item-editor">Gestione Servizi</a>
    <a class="nav-item nav-link" routerLink="/bookings-viewer"
      >Visualizza Appuntamenti</a
    >
    <button class="btn btn-outline-light my-2 my-sm-0" (click)="logout()">
      Esci
    </button>
  </div>
</nav>
