import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ItemEditorComponent } from './item-editor/item-editor.component';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { LoginComponent } from './login/login.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { BookingsViewerComponent } from './bookings-viewer/bookings-viewer.component';

// const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['home']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToBookingsViewer = () => redirectLoggedInTo(['bookings-viewer']);

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToBookingsViewer },
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
  },
  {
    path: 'item-editor',
    component: ItemEditorComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'bookings-viewer',
    component: BookingsViewerComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes), AngularFireAuthGuardModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
