import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = this.auth.user;

  constructor(public auth: AngularFireAuth) {
  }

  login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  logout(): Promise<void> {
    return this.auth.signOut();
  }

}
