import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BookingService } from '../booking.service';
import { Agenda } from '../interfaces/agenda';
import { Booking } from '../interfaces/booking';

@Component({
  selector: 'app-bookings-viewer',
  templateUrl: './bookings-viewer.component.html',
  styleUrls: ['./bookings-viewer.component.scss'],
})
export class BookingsViewerComponent implements OnInit {
  bookings$ = this.bookingService.bookings$;
  agenda: Agenda = {};

  constructor(private bookingService: BookingService) {}

  ngOnInit(): void {
    this.bookingService.bookings$.subscribe((bookings) => {
      return this.generateAgenda(bookings);
    });
  }

  orderByDate(
    a: KeyValue<string, Booking[]>,
    b: KeyValue<string, Booking[]>
  ): number {
    const getDateFromString = (date: string): Date => {
      const dateParts = date.split('/');
      // month is 0-based, that's why we need dataParts[1] - 1
      return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
    };
    return (
      getDateFromString(a.key).getTime() - getDateFromString(b.key).getTime()
    );
  }

  // TODO: Refactor per usare jsdate al posto di ore e minuti
  sortBookings(bookings: Booking[]): Booking[] {
    return bookings.sort(
      (a, b) =>
        (a.data.slot.startHour - b.data.slot.startHour) * 60 +
        a.data.slot.startMinute -
        b.data.slot.startMinute
    );
  }

  generateAgenda(bookings: Booking[]): void {
    this.agenda = {};
    bookings.map((booking) => {
      const day = booking.data.day.formattedDate;
      if (!this.agenda[day]) {
        this.agenda[day] = [];
      }
      this.agenda[day].push(booking);
    });
  }

  removeBooking(bookingId: Booking['id']): void {
    this.bookingService.removeBooking(bookingId);
  }
}
