import { Component } from '@angular/core';
import { BookingService } from '../booking.service';
import { Customer } from '../interfaces/customer';
import { Day } from '../interfaces/day';
import { Item } from '../interfaces/item';
import { Slot } from '../interfaces/slot';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  // tslint:disable: no-non-null-assertion
  item: Item = this.bookingService.item!;
  day: Day = this.bookingService.day!;
  slot: Slot = this.bookingService.slot!;
  customer?: Customer = this.bookingService.customer;

  constructor(private bookingService: BookingService) {}
}
