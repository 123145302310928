<div class="modal-body">
  <app-summary></app-summary>
  <h2>Dati anagrafici</h2>
  <form ngNativeValidate (ngSubmit)="confirm()">
    <div class="form-group row">
      <label for="name" class="col-sm-2 col-form-label">Nome</label>
      <div class="col-sm-10">
        <input
          [(ngModel)]="name"
          name="name"
          type="text"
          class="form-control"
          id="name"
          placeholder="Nome"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="cognome" class="col-sm-2 col-form-label">Cognome</label>
      <div class="col-sm-10">
        <input
          [(ngModel)]="surname"
          name="surname"
          type="text"
          class="form-control"
          id="cognome"
          placeholder="Cognome"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="phone" class="col-sm-2 col-form-label">Cellulare</label>
      <div class="col-sm-10">
        <input
          [(ngModel)]="phone"
          name="phone"
          type="text"
          class="form-control"
          id="cellulare"
          placeholder="Cellulare"
          required
        />
      </div>
    </div>
    <button class="btn btn-primary" type="submit">Conferma</button>
    <button
      (click)="activeModal.dismiss('cancel')"
      class="btn btn-danger float-right"
    >
      Annulla
    </button>
  </form>
</div>
