import { Component, OnInit } from '@angular/core';
import { ItemData } from '../interfaces/item';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-item-create',
  templateUrl: './item-create.component.html',
  styleUrls: ['./item-create.component.scss'],
})
export class ItemCreateComponent implements OnInit {
  item: ItemData;

  constructor(private itemService: ItemsService) {
    this.item = {
      name: '',
      duration: 0,
    };
  }

  ngOnInit(): void {}

  reset(): void {
    this.item = {
      name: '',
      duration: 0,
    };
  }

  add(): void {
    this.itemService.add(this.item);
    this.reset();
  }
}
