<div *ngFor="let day of this.agenda | keyvalue: orderByDate">
  <h1>{{ day.key }}</h1>
  <ul>
    <li *ngFor="let booking of sortBookings(day.value)">
      <button (click)="removeBooking(booking.id)"
              class="btn btn-primary mb-2">
        Rimuovi
      </button>
      <span>
        {{ booking.data.slot?.startHour | number: "2.0" }}:{{
        booking.data.slot?.startMinute | number: "2.0"
        }}-{{ booking.data.slot?.endHour | number: "2.0" }}:{{
        booking.data.slot?.endMinute | number: "2.0"
        }}
      </span>
      //
      <span>
        {{ booking.data.item?.data?.name }}
      </span>
      //
      <span>
        {{ booking.data.customer?.name }} {{ booking.data.customer?.surname }}
      </span>
      //
      <span>
        {{ booking.data.customer?.phone }}
      </span>
    </li>
  </ul>
</div>