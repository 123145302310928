import { Component, OnInit } from '@angular/core';
import { BookingService } from '../booking.service';
import { Day } from '../interfaces/day';
import { Item } from '../interfaces/item';
import { Slot } from '../interfaces/slot';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  item?: Item;
  day?: Day;
  slot?: Slot;
  constructor(private bookingService: BookingService) {}

  ngOnInit(): void {}

  returnTo(step: string): void {
    if (step === 'item') {
      this.addItem(undefined);
    }
    this.addDay(undefined);
  }

  addItem(item: Item | undefined): void {
    this.item = item;
    this.bookingService.setItem(item);
  }

  addDay(day: Day | undefined): void {
    this.day = day;
    this.bookingService.setDay(day);
  }

  addSlot(slot: Slot): void {
    this.slot = slot;
    this.bookingService.setSlot(slot);
  }
}
