import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  err = '';
  email = '';
  password = '';

  constructor(
    private authServive: AuthService,
    private router: Router,
    private auth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.redirectWhenLoggedIn();
  }

  login(): void {
    this.authServive
      .login(this.email, this.password)
      .then(() => { })
      .catch((err) => {
        this.err = err.message;
        console.warn(err);
      });
  }

  redirectWhenLoggedIn(): void {
    this.auth.authState.subscribe((authState) => {
      if (authState) {
        this.router.navigate(['bookings-viewer']);
      }
    });
  }
}
