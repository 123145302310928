import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingData } from './interfaces/booking';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  apiKey = '0CE91BF7E1495C8BC89119C6F5CC172B5C0178F3C9479DBE2D4BAE49C7CEB1FE50607CAAF1A4FFF8CBDE09881D3423FC';
  apiUrl = 'https://api.elasticemail.com';
  apiVersion = 'v2';

  constructor(private http: HttpClient) {

  }

  sendBookingNotification(bookingData: BookingData): void {
    const endpoint = new URL(`${this.apiUrl}/${this.apiVersion}/email/send?apiKey=${this.apiKey}`);
    const subject = 'AgendaViola: nuova prenotazione';
    const from = 'info@agenziaviola.it';
    const to = 'violaisa2578@sermetra.it';

    const startHour = String(bookingData.slot.startHour).padStart(2, '0');
    const startMinute = String(bookingData.slot.startMinute).padStart(2, '0');
    const endHour = String(bookingData.slot.endHour).padStart(2, '0');
    const endMinute = String(bookingData.slot.endMinute).padStart(2, '0');

    let body = `Servizio: ${bookingData.item.data.name} <br>`;
    body += `Data: ${bookingData.day.jsdate.toLocaleDateString()} <br>`;
    body += `Dalle ore ${startHour}:${startMinute} alle ore ${endHour}:${endMinute} <br>`;
    body += `<br>`;
    body += `${bookingData.customer.surname} ${bookingData.customer.name} <br>`;
    body += `Telefono: ${bookingData.customer.phone} <br>`;

    endpoint.searchParams.append('subject', subject);
    endpoint.searchParams.append('from', from);
    endpoint.searchParams.append('to', to);
    endpoint.searchParams.append('bodyHtml', body);
    this.http.post(endpoint.toString(), {}).subscribe((res) => console.log(res));
  }
}
