<div class="login-form">
  <form>
    <h2 class="text-center">Log in</h2>
    <div class="form-group">
      <input
        [(ngModel)]="email"
        type="text"
        class="form-control"
        placeholder="Email"
        required="required"
        name="email"
      />
    </div>
    <div class="form-group">
      <input
        [(ngModel)]="password"
        placeholder="Password"
        type="password"
        class="form-control"
        required="required"
        name="password"
      />
    </div>
    <div class="form-group">
      <button (click)="login()" class="btn btn-primary btn-block">
        Log in
      </button>
    </div>
  </form>
</div>
<div *ngIf="err">{{ err }}</div>
