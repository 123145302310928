import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item, ItemData } from './interfaces/item';
@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  item$: Observable<Item[]>;

  constructor(private firestore: AngularFirestore) {
    this.item$ = this.firestore
      .collection('items')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((action) => {
            const data = action.payload.doc.data() as ItemData;
            const id = action.payload.doc.id;
            return { id, data };
          })
        )
      );
  }

  add(item: ItemData): void {
    this.firestore
      .collection('items')
      .add(item)
      .then((documentReference) => {})
      .catch((err) => console.error(err));
  }

  remove(itemId: string): void {
    this.firestore
      .collection('items')
      .doc(itemId)
      .delete()
      .then(() => console.log('deleted'))
      .catch((err) => console.error(err));
  }

  update(item: Item): void {
    this.firestore
      .collection('items')
      .doc(item.id)
      .update(item.data)
      .then(() => console.log('updated'))
      .catch((err) => console.error(err));
  }
}
