import { Injectable } from '@angular/core';
import { BookingService } from './booking.service';
import { BookingsViewerComponent } from './bookings-viewer/bookings-viewer.component';
import { Booking } from './interfaces/booking';
import { ItemData } from './interfaces/item';
import { DayOpening, Opening } from './interfaces/opening';
import { Slot } from './interfaces/slot';

@Injectable({
  providedIn: 'root',
})
export class SlotService {
  bookings: Booking[] = [];
  slots: Slot[] = [];
  day: Date = new Date();
  // - Orario
  //  09:00-12:30 e 16:00- 19:00 Lunedì-venerdì
  //  09:00-12:30                Sabato
  opening: DayOpening = {
    morning: {
      startHour: 9,
      startMinute: 0,
      endHour: 12,
      endMinute: 30,
    },
    afternoon: {
      startHour: 16,
      startMinute: 0,
      endHour: 19,
      endMinute: 0,
    },
  };

  constructor(private bookingService: BookingService) {
    this.bookingService.bookings$.subscribe((bookings) => {
      this.bookings = bookings;
      this.setSlotsAvailability();
    });
  }

  getSlots(item: ItemData, day: Date): Slot[] {
    this.slots = [];
    this.day = day;
    if (this.isSunday()) {
      return [];
    }

    const SLOT_DURATION = item.duration * 60000;

    this.slots.push(...this.generateSlots(this.opening.morning, SLOT_DURATION));
    if (this.isOpenOnAfternoon()) {
      this.slots.push(
        ...this.generateSlots(this.opening.afternoon, SLOT_DURATION)
      );
    }
    this.setSlotsAvailability();
    return this.slots;
  }

  private generateSlots(
    opening: Opening,
    duration: ItemData['duration']
  ): Slot[] {
    const slots: Slot[] = [];
    const slotStartTime = this.getDateWithHours(
      opening.startHour,
      opening.startMinute
    );
    const openingEnd = this.getDateWithHours(
      opening.endHour,
      opening.endMinute
    );

    for (let i = 0; slotStartTime < openingEnd; i++) {
      const slotEndTime = new Date(slotStartTime.getTime() + duration);
      if (slotEndTime > openingEnd) {
        break;
      }
      slots.push({
        startHour: slotStartTime.getHours(),
        startMinute: slotStartTime.getMinutes(),
        endHour: slotEndTime.getHours(),
        endMinute: slotEndTime.getMinutes(),
        available: false,
      });

      slotStartTime.setTime(slotStartTime.getTime() + duration);
    }
    return slots;
  }

  private setSlotsAvailability(): Slot[] {
    return this.slots.map((slot) => {
      slot.available = this.isSlotAvailable(slot);
      return slot;
    });
  }

  private isSlotAvailable(slot: Slot): boolean {
    const MAX_COLLAPSING_BOOKINGS = 1;
    const dailyBookings = this.bookings.filter((booking) =>
      this.filterDailyBookings(booking, this.day)
    );
    if (dailyBookings.length === 0) {
      return true;
    }
    const collapsingBookings = dailyBookings.filter((booking) =>
      this.filterCollapsingBookings(booking, slot)
    );
    return collapsingBookings.length <= MAX_COLLAPSING_BOOKINGS;
  }

  private filterDailyBookings(booking: Booking, day: Date): boolean {
    return (
      booking.data.day.jsdate.setHours(0, 0, 0, 0) === day.setHours(0, 0, 0, 0)
    );
  }

  private filterCollapsingBookings(booking: Booking, slot: Slot): boolean {
    const slotStartTime = this.getDateWithHours(
      slot.startHour,
      slot.startMinute
    );
    const slotEndTime = this.getDateWithHours(slot.endHour, slot.endMinute);
    const bookingStartTime = this.getDateWithHours(
      booking.data.slot.startHour,
      booking.data.slot.startMinute
    );
    const bookingEndTime = this.getDateWithHours(
      booking.data.slot.endHour,
      booking.data.slot.endMinute
    );
    const sameAsSlot =
      bookingStartTime.getTime() === slotStartTime.getTime() &&
      bookingEndTime.getTime() === slotEndTime.getTime();
    const isInsideSlot =
      bookingStartTime.getTime() >= slotStartTime.getTime() &&
      bookingEndTime.getTime() <= slotEndTime.getTime();
    const collapseOnEnd =
      bookingStartTime.getTime() < slotEndTime.getTime() &&
      bookingEndTime.getTime() > slotEndTime.getTime();
    const collapseOnStart =
      bookingStartTime.getTime() < slotStartTime.getTime() &&
      bookingEndTime.getTime() > slotStartTime.getTime();
    return sameAsSlot || isInsideSlot || collapseOnEnd || collapseOnStart;
  }

  private isSunday(): boolean {
    const SUNDAY = 0;
    return this.day.getDay() === SUNDAY;
  }

  private isOpenOnAfternoon(): boolean {
    const SATURNDAY = 6;
    return this.day.getDay() !== SATURNDAY;
  }

  private getDateWithHours(
    hour: number,
    minute: number,
    refDate: Date = this.day
  ): Date {
    const date = new Date(refDate);
    date.setHours(hour, minute, 0);
    return date;
  }
}
