<app-item-create></app-item-create>

<table class="table">
  <thead>
    <tr>
      <th scope="col">Nome</th>
      <th scope="col">Durata</th>
      <th scope="col">Elimina</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of item$ | async">
      <td>{{item.data.name}}</td>
      <td>{{item.data.duration}}</td>
      <td><button class="btn btn-danger mb-2"
                (click)="removeItem(item.id)">Elimina</button></td>
    </tr>
  </tbody>
</table>