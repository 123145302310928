<form class="form-inline">
  <label for="name">Nome</label>
  <input
    [(ngModel)]="item.name"
    type="text"
    class="form-control mb-2 mr-sm-2"
    placeholder="Nome servizio"
    id="name"
    name="name"
  />
  <label for="duration">Durata</label>
  <input
    [(ngModel)]="item.duration"
    type="text"
    class="form-control mb-2 mr-sm-2"
    placeholder="Durata"
    id="duration"
    name="duration"
  />

  <button (click)="add()" class="btn btn-primary mb-2">Crea</button>
</form>
