import { Component, OnInit } from '@angular/core';
import { ItemsService } from '../items.service';
import { Output, EventEmitter } from '@angular/core';
import { Item } from '../interfaces/item';

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
})
export class ItemSelectorComponent implements OnInit {
  @Output() itemSelectedEvent = new EventEmitter<Item>();
  item$ = this.itemsService.item$;
  selectedItem?: Item;

  constructor(private itemsService: ItemsService) {}

  ngOnInit(): void {}

  selectItem(item: Item): void {
    this.itemSelectedEvent.emit(item);
    this.selectedItem = item;
  }
}
