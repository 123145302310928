<h1>Seleziona Orario</h1>
<div class="row">
  <div
    *ngFor="let slot of slots"
    class="col-6 col-md-4 col-xl-3 text-center"
    (click)="selectSlot(this.slot)"
  >
    <button
      class="btn btn-outline-primary slot"
      [ngClass]="{
        active:
          this.selectedSlot?.startHour === slot.startHour &&
          this.selectedSlot?.endHour === slot.endHour
      }"
      [disabled]="!slot.available"
    >
      {{ slot.startHour | number: "2.0" }}:{{
        slot.startMinute | number: "2.0"
      }}-{{ slot.endHour | number: "2.0" }}:{{ slot.endMinute | number: "2.0" }}
    </button>
  </div>
</div>
