import { Component, OnInit } from '@angular/core';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-item-editor',
  templateUrl: './item-editor.component.html',
  styleUrls: ['./item-editor.component.scss']
})
export class ItemEditorComponent implements OnInit {

  item$ = this.itemsService.item$;

  constructor(private itemsService: ItemsService) { }

  ngOnInit(): void {
  }

  removeItem(itemId: string): void {
    this.itemsService.remove(itemId);
  }

}
