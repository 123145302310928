import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Day } from '../interfaces/day';
import { ItemData } from '../interfaces/item';
import { Slot } from '../interfaces/slot';
import { SlotService } from '../slot.service';

@Component({
  selector: 'app-slot-selector',
  templateUrl: './slot-selector.component.html',
  styleUrls: ['./slot-selector.component.scss'],
})
export class SlotSelectorComponent implements OnInit {
  @Output() slotSelectedEvent = new EventEmitter<Slot>();
  @Input() item!: ItemData;
  @Input() day!: Day;

  slots: Slot[] = [];
  selectedSlot?: Slot;

  constructor(private slotService: SlotService) {}

  ngOnInit(): void {
    this.slots = this.slotService.getSlots(this.item, this.day.jsdate);
  }

  selectSlot(slot: Slot): void {
    if (!slot.available) {
      return;
    }
    this.selectedSlot = slot;
    this.slotSelectedEvent.emit(slot);
  }
}
