import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BookingService } from '../booking.service';
import { Day } from '../interfaces/day';

@Component({
  selector: 'app-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss'],
})
export class DaySelectorComponent implements OnInit {
  @Output() daySelectedEvent = new EventEmitter<Day>();

  days: Day[] = [];
  selectedDay?: Day;

  constructor() {}

  ngOnInit(): void {
    this.days = this.getDaysArray();
  }

  selectDay(day: Day): void {
    if (day.jsdate.getDay() === 0) {
      return;
    }
    this.selectedDay = day;
    this.daySelectedEvent.emit(day);
  }

  private getDaysArray(): Day[] {
    const DAYS_AVAILABLE = 9;
    const days: Day[] = [];
    let currentDay = new Date();
    for (let i = 0; days.length < DAYS_AVAILABLE; i++) {
      currentDay = new Date(currentDay);
      currentDay.setDate(currentDay.getDate() + 1);
      days.push({
        jsdate: currentDay,
        day: this.getDayName(currentDay.getDay()),
        date: currentDay.getDate(),
        month: this.getMonthName(currentDay.getMonth()),
        year: currentDay.getFullYear(),
        formattedDate: this.getFormattedDate(currentDay),
      });
    }
    return days;
  }

  private getFormattedDate(date: Date): string {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join('/');
  }

  private getDayName(day: number): string {
    switch (day) {
      case 1:
        return 'Lunedì';
      case 2:
        return 'Martedì';
      case 3:
        return 'Mercoledì';
      case 4:
        return 'Giovedì';
      case 5:
        return 'Venerdì';
      case 6:
        return 'Sabato';
      case 0:
        return 'Domenica';
      default:
        return '';
    }
  }

  private getMonthName(month: number): string {
    switch (month) {
      case 0:
        return 'Gennaio';
      case 1:
        return 'Febbraio';
      case 2:
        return 'Marzo';
      case 3:
        return 'Aprile';
      case 4:
        return 'Maggio';
      case 5:
        return 'Giugno';
      case 6:
        return 'Luglio';
      case 7:
        return 'Agosto';
      case 8:
        return 'Settembre';
      case 9:
        return 'Ottobre';
      case 10:
        return 'Novembre';
      case 11:
        return 'Dicembre';
      default:
        return '';
    }
  }
}
