<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" [ngClass]="{ active: !this.item }">
      <a *ngIf="this.item" href="#" (click)="returnTo('item')">Servizio</a>
      <span *ngIf="!this.item">Servizio</span>
    </li>
    <li
      *ngIf="this.item"
      class="breadcrumb-item"
      [ngClass]="{ active: this.item && !this.day }"
      (click)="returnTo('day')"
    >
      <a *ngIf="this.day" href="#" (click)="returnTo('day')">Giorno</a>
      <span *ngIf="!this.day">Giorno</span>
    </li>
    <li
      *ngIf="this.item && this.day"
      class="breadcrumb-item active"
      aria-current="page"
    >
      Orario
    </li>
  </ol>
</nav>

<div *ngIf="!this.item">
  <app-item-selector (itemSelectedEvent)="addItem($event)"></app-item-selector>
</div>

<div *ngIf="this.item && !this.day">
  <app-day-selector (daySelectedEvent)="addDay($event)"></app-day-selector>
</div>

<div *ngIf="this.item && this.day">
  <app-slot-selector
    (slotSelectedEvent)="addSlot($event)"
    [day]="this.day"
    [item]="this.item.data"
  ></app-slot-selector>
</div>
