import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import firebase from 'firebase/app';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user$ = this.authService.user$;
  isAdmin = false;
  isNavbarCollapsed = true;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.authService.user$
      .subscribe((user): void => {
        if (!user) {
          return;
        }
        this.isAdmin = this.isUserAdmin(user);
      });
  }

  logout(): void {
    this.authService.logout();
    this.isAdmin = false;
    this.router.navigate(['home']);
  }

  isUserAdmin(user: firebase.User): boolean {
    return user.email === 'riccardomessineo@gmail.com' || user.email === 'violaisa2578@sermetra.it';
  }

}
