import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingModalComponent } from './booking-modal/booking-modal.component';
import { EmailService } from './email.service';
import { Booking, BookingData } from './interfaces/booking';
import { Customer } from './interfaces/customer';
import { Day } from './interfaces/day';
import { Item } from './interfaces/item';
import { Slot } from './interfaces/slot';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  item?: Item;
  day?: Day;
  slot?: Slot;
  customer?: Customer;
  bookings$: Observable<Booking[]>;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private firestore: AngularFirestore,
    private emailService: EmailService
  ) {
    this.bookings$ = this.firestore
      .collection('bookings')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((action) => {
            const data = action.payload.doc.data() as any;
            const id = action.payload.doc.id;
            data.day.jsdate = data.day.jsdate.toDate();
            const bookingData = data as BookingData;
            return { id, data: bookingData };
          })
        )
      );
    this.removeOldBookings();
  }

  addBooking(booking: BookingData): Promise<void> {
    return this.firestore
      .collection('bookings')
      .add(booking)
      .then((documentReference) => { })
      .catch((err) => console.error(err));
  }

  removeOldBookings(): void {
    const aWeekBefore = new Date();
    aWeekBefore.setDate(aWeekBefore.getDate() - 7);

    this.firestore
      .collection('bookings', (ref) =>
        ref.where('day.jsdate', '<', aWeekBefore)
      )
      .get()
      .subscribe((oldBookings) => {
        oldBookings.docs.forEach((doc) =>
          doc.ref.delete().catch((err) => console.log(err))
        );
      });
  }

  removeBooking(bookingId: Booking['id']): Promise<void> {
    return this.firestore
      .collection('bookings')
      .doc(bookingId)
      .delete()
      .then(() => { })
      .catch((err) => console.error(err));
  }

  setItem(item: Item | undefined): void {
    this.item = item;
  }

  setDay(day: Day | undefined): void {
    this.day = day;
  }

  setSlot(slot: Slot): void {
    this.slot = slot;
    this.openSummaryModal();
  }

  setCustomer(customer: Customer): void {
    this.customer = customer;
  }

  openSummaryModal(): void {
    const modalRef = this.modalService.open(BookingModalComponent);
    modalRef.result.then(
      (result) => {
        this.setCustomer(modalRef.componentInstance.customer);
        this.saveBooking();
      },
      (reason) => {
        console.log(`Dismissed: ${reason}`);
      }
    );
  }

  saveBooking(): void {
    if (!this.item || !this.day || !this.slot || !this.customer) {
      return;
    }
    const bookingData: BookingData = {
      item: this.item,
      day: this.day,
      slot: this.slot,
      customer: this.customer
    };
    this.addBooking(bookingData).then(() => {
      this.emailService.sendBookingNotification(bookingData);
      this.goToThankYouPage();
    }
    );
  }

  goToThankYouPage(): void {
    this.router.navigate(['thank-you']);
  }
}
