import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ItemEditorComponent } from './item-editor/item-editor.component';
import { ItemSelectorComponent } from './item-selector/item-selector.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ItemCreateComponent } from './item-create/item-create.component';
import { LoginComponent } from './login/login.component';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { SlotSelectorComponent } from './slot-selector/slot-selector.component';
import { SummaryComponent } from './summary/summary.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { BookingsViewerComponent } from './bookings-viewer/bookings-viewer.component';
import { BookingModalComponent } from './booking-modal/booking-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ItemEditorComponent,
    ItemSelectorComponent,
    CalendarComponent,
    ItemCreateComponent,
    LoginComponent,
    DaySelectorComponent,
    SlotSelectorComponent,
    SummaryComponent,
    ThankYouComponent,
    BookingsViewerComponent,
    BookingModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgbModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
